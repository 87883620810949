import React from "react";

import { Box, Flex, FlexProps, Text } from "Atoms";
import { Addon, Modifications, OrderProduct } from "Types";
import { formatFinancialNumbers } from "Utils";
import { useQoplaStore } from "Stores/qoplaStore";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { getModifications } from "TempUtils";
import { modConstants } from "Constants";
import { ExpressCartContentDiscount } from "./ExpressCartContentDiscount";

type Props = {
    orderProduct: OrderProduct;
    isPriceModification: boolean;
    isSingleModsPriceIncluded: boolean;
} & FlexProps;

export const ExpressCartContentCardDetails: React.FC<Props> = ({
    orderProduct,
    isPriceModification,
    isSingleModsPriceIncluded,
    ...rest
}) => {
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();

    // quantity is extracted to show the summarized price for the order product
    const { selectedBundleProductItems, modifications, addons, quantity: orderProductQuantity, comment } = orderProduct;

    const addonsList = (addons: Addon[]) =>
        addons?.map(({ name, price, quantity }, idx: number) => {
            const quantityText = quantity > 1 ? ` ${quantity}x ` : " ";
            const countPrice = price * quantity * orderProductQuantity;
            return (
                <Flex
                    height={"max-content"}
                    key={idx}
                    {...rest}
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                >
                    <Text m={0}>{`+${quantityText}${name}`}</Text>
                    {price > 0 && <Text>{`+ ${formatFinancialNumbers(countPrice, companyLocale)}`}</Text>}
                </Flex>
            );
        });

    const modificationsList = (modificationsArray: Modifications) =>
        Object.entries(modificationsArray)
            .sort(([key1], [key2]) => (key1 === modConstants.MOD_SIZES ? -1 : key2 === modConstants.MOD_SIZES ? 1 : 0))
            .map(([key, value]) => {
                // need this since Modification in orderProduct can be Modifications or Modifications[]
                // but in cart we can only have one selected modification
                if (value && !Array.isArray(value)) {
                    const { name, price, addonPrice } = value;
                    const priceToUse = addonPrice !== 0 ? addonPrice : price;
                    const priceToUseWithQuantity = priceToUse * orderProductQuantity;
                    const showPrice = addonPrice !== 0 && !selectedBundleProductItems && !isSingleModsPriceIncluded;
                    const hasName = name?.trim().length > 0;
                    const isNegativePrice = priceToUse < 0;
                    const translationKey = key === modConstants.MOD_SIZES ? "size" : "flavours";
                    if (hasName) {
                        return (
                            <Flex
                                mt={1}
                                height={"max-content"}
                                key={key}
                                fontSize={isPortrait ? "md" : "sm"}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text m={0}>{`${translate(translationKey)}: ${name}`}</Text>
                                {priceToUse !== 0 && showPrice && (
                                    <Text>{`${!isNegativePrice ? "+" : ""} ${formatFinancialNumbers(
                                        priceToUseWithQuantity,
                                        companyLocale
                                    )}`}</Text>
                                )}
                            </Flex>
                        );
                    }
                }
            });

    const hasExtras =
        (!!modifications && isPriceModification) || !!addons?.length || !!selectedBundleProductItems?.length;
    const hasDiscount = (orderProduct?.discountValue ?? 0) > 0;

    return (
        <Box height={"max-content"} mb={isPortrait ? 4 : 0}>
            {!!selectedBundleProductItems &&
                selectedBundleProductItems.map(({ name, modifications, addons }, idx: number) => {
                    /** Bundle Item selection - looking at online and old express we only use addon price for bundle item mods
                     * The price part is not added to the cost of the bundle item, only the addonPrice is added
                     */
                    const modificationsPrice = !!modifications
                        ? getModifications(modifications).reduce(
                              (price: number, mod: any) =>
                                  price + (mod.mods.addonPrice !== 0 ? mod.mods.addonPrice : 0),
                              0
                          )
                        : 0;
                    const modPriceWithQuantity = modificationsPrice * orderProductQuantity;
                    const isNegativePrice = modPriceWithQuantity < 0;
                    return (
                        <Flex key={idx} flexDirection="column" height="max-content" {...rest}>
                            <Flex mt={2} height="max-content" alignItems="center" justifyContent="space-between">
                                <Text m={0}>{name}</Text>
                                {modificationsPrice !== 0 && (
                                    <Text m={0}>
                                        {`${!isNegativePrice ? "+" : ""} ${formatFinancialNumbers(
                                            modPriceWithQuantity,
                                            companyLocale
                                        )}`}
                                    </Text>
                                )}
                            </Flex>
                            <Box pl={2}>{!!modifications && modificationsList(modifications)}</Box>
                            <Box pl={2}>{!!addons && addonsList(addons)}</Box>
                        </Flex>
                    );
                })}
            {!!modifications && modificationsList(modifications)}
            {!!addons && addonsList(addons)}
            {!!comment && (
                <Flex mt={2} height={"max-content"} {...rest}>
                    <Text m={0}>{`${translate("comment")}: ${comment}`}</Text>
                </Flex>
            )}
            {hasExtras && hasDiscount ? <ExpressCartContentDiscount orderProduct={orderProduct} /> : null}
        </Box>
    );
};

import React, { PropsWithChildren } from "react";
import { Variants } from "framer-motion";

import { MotionDiv } from "Atoms/MotionBox";
import { SlideDirection } from "Organisms";

export type BundleSlideDirection = Extract<SlideDirection, "left" | "right">;

const animationDirection: Record<BundleSlideDirection, Variants> = {
    right: {
        initialState: {
            x: "100%"
        },
        animateState: {
            x: 0
        },
        exitState: {
            x: "-100%"
        }
    },
    left: {
        initialState: {
            x: "-100%"
        },
        animateState: {
            x: 0
        },
        exitState: {
            x: "100%"
        }
    }
};

export const BundleCreatorInternalMotion: React.FC<
    PropsWithChildren<{ view: string | number; direction?: BundleSlideDirection }>
> = ({ view, children, direction = "right" }) => {
    return (
        <MotionDiv
            key={view}
            initial="initialState"
            animate="animateState"
            exit="exitState"
            transition={{ duration: 0.3 }}
            variants={animationDirection[direction]}
            style={{ height: "100%", width: "100%", overflowX: "hidden" }}
        >
            {children}
        </MotionDiv>
    );
};
